import React from "react";
import ScrollToTop from "../components/ScrollToTop";
import Signin from "../components/Signin";

const SigninPage = () => {
  return (
    <div>
      <ScrollToTop />
      <Signin />
    </div>
  );
};

export default SigninPage;
